@import "./Shared.scss";
.services-boxes {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;

  @include md() {
    grid-template-columns: 1fr;
  }
}
