@import "./Shared.scss";

.executive-search {
  & ._flex {
    gap: 100px;

    @include md() {
      gap: 0px;
    }
  }

  & .part-2,
  .part-3 {
    margin-top: 5rem;

    @include md() {
      margin-top: 1rem;
    }
  }

  & .google-images,
  .team-images {
    position: relative;
    max-height: 500px;
    margin-top: 4rem;
    margin-bottom: 2rem;

    @include lg() {
      max-height: 400px;
    }

    @include md() {
      max-width: 400px;
      align-self: center;
      margin-top: 1rem !important;
    }

    & .main-image {
      width: 500px;

      @include lg() {
        width: 400px;
      }
    }
    & .absolute-image {
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translate(50px, -50px);
      width: 300px;

      @include lg() {
        width: 200px;
      }

      @include md() {
        transform: translate(0px, 0px);
      }
    }
  }

  & .team-images {
    @include md() {
      margin-bottom: 40px;
    }
    & .absolute-image {
      left: -100px;
      top: 0;

      @include md() {
        left: -50px;
        top: 0;
        width: 150px;
      }
    }
  }
}
