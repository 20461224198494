@import "./Shared.scss";

.temp-staffing {
  & .on-demand-title {
    font-size: 3rem;
    text-align: center;
    margin-top: 5rem;
    margin-bottom: 2rem;
    line-height: 1;
    flex-wrap: wrap;

    @include md() {
      font-size: 2.5rem;
    }
  }

  & .experience {
    margin-top: 5rem;

    & h1 {
      white-space: nowrap;
      margin-top: 2rem;

      @include md {
        white-space: initial;
      }
    }

    
  }
}
