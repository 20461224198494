@import "./Shared.scss";

 .main-title {
    font-size: 6rem;
    line-height: 1;
    margin-top: 5rem;

    @include lg() {
      font-size: 5rem;
    }

    @include md() {
      font-size: 3.4rem;
    }

    @include sm {
      font-size: 2.9rem;
    }
  }

  

  .content {
    align-self: center;
  }

  .sub-title {
    font-size: 4rem;

    @include xl() {
      font-size: 3rem;
    }

        @include lg() {
      font-size: 2.5rem;
    }
  }

  .description {
    font-family: Oswald-regular;
    font-size: 1.7rem;
    margin-top: 2rem;

    @include xl() {
      font-size: 1.5rem;
    }
    
    @include lg() {
      font-size: 1.2rem;
    }
  }