@import "./Shared.scss";
.services {
  @include md {
    margin-top: 0rem !important;
  }
  & .services-welcome {
    margin-top: 5rem;
    display: grid;
    grid-template-columns: 0.7fr 1.5fr;

    @include md() {
      grid-template-columns: minmax(0, 1fr);
      margin-top: 2rem;
    }

    & .title {
      font-size: 3.4rem;
      line-height: 1;
      font-family: Oswald-bold;

      @include xl-min() {
        font-size: 4rem;
      }

      @include lg() {
        font-size: 3.2rem;
      }

      @include md() {
        margin-bottom: 1.5rem;
      }

      @include sm() {
        font-size: 2.5rem;
      }
    }

    & .description {
      font-size: 1.8rem;
      font-family: Oswald-regular;

      @include xl-min() {
        font-size: 2rem;
      }

      @include lg() {
        font-size: 1.5rem;
      }
    }
  }
  & .map-container {
    & img {
      width: 100%;
    }
  }

  & .services-unlock-success {
    display: grid;
    grid-template-columns: 0.7fr 1.5fr;

    @include md() {
      grid-template-columns: minmax(0, 1fr);
    }

    & .title {
      font-size: 4rem;
      line-height: 1.3;

      @include xl() {
        font-size: 3rem;
      }

      @include lg() {
        font-size: 2.8rem;
      }

      @include md() {
        margin-bottom: 2rem;
      }

      @include sm() {
        font-size: 2.5rem;
      }
    }

    & .description {
      & .description-title {
        font-size: 2.5rem;
        line-height: 1;

        @include xl() {
          font-size: 2rem;
        }

        @include lg() {
          font-size: 1.8rem;
        }

        @include md() {
          font-size: 1rem;
        }
      }
      & .description-text {
        margin-top: 1.6rem;
        font-family: Oswald-regular;
        font-size: 1.8rem;

        @include md() {
          font-size: 1rem;
          margin-top: 0.5rem;
        }
      }
    }
  }

  & .customized-solutions {
    margin-top: 5rem;

    @include md {
      margin-top: 2rem;
    }
    & .title {
      font-size: 3rem;
      line-height: 1;
      margin-bottom: 2.5rem;

      @include md() {
        font-size: 2rem;
      }

      & .sub-title {
        font-size: 2rem;

        @include md() {
          font-size: 2rem;
        }
      }
    }

    & .solutions {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 30px;

      @include md() {
        grid-template-columns: minmax(0, 1fr);
      }

      & .insight {
        display: flex;
        flex-direction: column;
        justify-content: center;
        & .title {
          font-size: 2.3rem;

          @include lg() {
            font-size: 1.8rem;
          }

          @include md {
            margin-top: 1rem;
            font-size: 1.6rem;
          }
        }

        & .description {
          font-family: Oswald-regular;
          font-size: 1.5rem;
          @include md {
            margin-top: 0rem;
          }
        }
      }

      & .contact-card {
        background: $bgWhite;
        padding: 3rem;
        border-radius: 37px;
        box-shadow: 0px 0px 30px 5px rgba(35, 191, 214, 0.25);
        display: flex;
        flex-direction: column;

        @include md {
          padding: 2rem;
        }
      }
    }
  }

  & .details {
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    gap: 40px;
    margin-top: 10rem;

    @include md() {
      grid-template-columns: minmax(0, 1fr);

      margin-top: 3rem;
    }

    & .description {
      & .description-title {
        font-size: 1.5rem;
      }

      & .description-text {
        margin-top: 1rem;
      }
    }

    & .unparalleled-expertise {
      background: $primaryBtnBgColour;

      & .big-title {
        line-height: 1.1;

        @include md {
          font-size: 2rem;
        }
      }

      & .long-card-description {
        @include md {
          font-size: 1.5rem;
        }
      }
    }

    & .drive-growth {
      & .contact-card {
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
      }
    }
  }

  & .details-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "main-title title-desc"
      "contact-card temp-staffing";
    gap: 10px 50px;
    margin-top: 10rem;

    @include md() {
      grid-template-columns: minmax(0, 1fr);
      grid-template-areas:
        "main-title"
        "contact-card"
        "title-desc"
        "temp-staffing";
      margin-top: 3rem;
    }

    & .stay-agile {
      display: grid;
      grid-area: main-title;
    }

    & .description {
      & .description-title {
        margin-top: 1rem;
        font-size: 1.5rem;
      }
    }

    & .title-description {
      grid-area: title-desc;

      @include md() {
        margin-top: 2rem;
      }
    }

    & .contact-card {
      margin-top: 3rem;
      justify-self: first baseline;
      grid-area: contact-card;
      display: flex;
      flex-direction: column;

      .contact-btn {
        align-self: center;
      }
    }

    & .temporary-staffing {
      background: $primaryBtnBgColour;
      display: flex;
      flex-direction: column;
      position: relative;
      grid-area: temp-staffing;

      @include md() {
        margin-top: 2rem;
      }

      & .long-card-description {
        @include md {
          font-size: 1.5rem;
        }
      }

      & img {
        max-width: 400px;
        position: absolute;
        bottom: 0;
        right: 0;

        @include lg() {
          width: 250px;
        }

        @include md() {
          position: static;
          width: 300px;
          align-self: flex-end;
          transform: translate(30px, 30px);
        }
      }
    }
  }

  & .details-3 {
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    gap: 30px 50px;
    margin-top: 10rem;

    @include md() {
      grid-template-columns: minmax(0, 1fr);
      margin-top: 3rem;
    }

    & .drive-organizational {
      display: grid;

      & .big-title {
        line-height: 1.2;
      }
    }

    & .description {
      & .description-title {
        font-size: 1.5rem;
      }
    }

    & .title-description {
      @include md() {
        margin-top: 2rem;
      }
    }

    & .scal-flex {
      margin-top: -3rem;

      @include md() {
        margin-top: auto;
      }

      & .sub-title {
        margin-bottom: 3rem;

        @include md() {
          font-size: 1.5rem;
          margin-top: 1rem;
          margin-bottom: 1rem;
        }
      }
    }

    & .contact-card {
      margin-top: 3rem;
      justify-self: first baseline;
      display: flex;
      flex-direction: column;

      .contact-btn {
        align-self: center;
      }
    }

    & .transform-talent {
      background: $cardBgPink;
      display: flex;
      flex-direction: column;
      position: relative;
      margin-top: 4rem;

      @include md() {
        margin-top: 2rem;
      }

      & .description {
        font-family: Oswald-regular;
        font-size: 1.5rem;
        max-width: 82%;
        margin-top: 1rem;

        @include md() {
          max-width: 100%;
        }
      }
    }
  }

  & .details-4 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr auto;
    grid-auto-flow: dense;
    margin-bottom: 5rem;

    grid-template-areas:
      "main-title title-desc"
      "contact-card temp-staffing";
    gap: 20px 50px;
    margin-top: 10rem;

    @include md() {
      grid-template-columns: minmax(0, 1fr);
      grid-template-areas:
        "main-title"
        "contact-card"
        "title-desc"
        "temp-staffing";
      margin-top: 3rem;
    }

    & .drive-organizational {
      display: grid;
      grid-area: main-title;

      & .title-2 {
        margin-top: 1.5rem;
        line-height: 1;
      }
    }

    & .description {
      & .description-title {
        @include md() {
          font-size: 1.5rem;
        }
      }
    }

    & .title-description {
      grid-area: title-desc;

      @include md() {
        margin-top: 2rem;
      }
    }

    & .contact-card {
      margin-top: 3rem;
      grid-area: contact-card;
      display: flex;
      flex-direction: column;

      .contact-btn {
        align-self: center;
      }
    }

    & .unlock-power {
      background: $primaryBtnBgColour;
      display: flex;
      flex-direction: column;
      position: relative;
      grid-area: temp-staffing;

      @include md() {
        margin-top: 2rem;
      }

      & .description {
        font-family: Oswald-regular;
        font-size: 1.8rem;
        margin-top: 1rem;

        @include md {
          font-size: 1.5rem;
        }
      }

      & img {
        max-width: 400px;
        position: absolute;
        bottom: 0;
        right: 0;

        @include lg() {
          width: 250px;
        }

        @include md() {
          position: static;
          width: 300px;
          align-self: flex-end;
          transform: translate(30px, 30px);
        }
      }
    }
  }
}

//common
.big-title {
  font-size: 3rem;
  line-height: 1.1;

  @include lg {
    font-size: 2.5rem;
  }
}

.sub-title {
  font-size: 2.5rem;
  line-height: 1;
}

.title-2 {
  margin-top: 4rem;
  font-size: 2.5rem;
  align-self: end;

  @include md() {
    display: none;
  }
}

.title-2-show-md {
  display: none;
  margin: 0.5rem 0;

  @include md() {
    display: block;
    font-size: 1.3rem;
  }
}

.paragraph {
  font-family: Oswald-regular;
  font-size: 1.3rem;
}

.mt {
  margin-top: 1.2rem;
}

.block {
  display: block;
}

.long-card {
  padding: 2rem;
  border-radius: 40px;
}

.grid {
  display: grid;
}

.contact-btn {
  margin-top: 5rem;
  align-self: center;

  @include lg() {
    align-self: center;
  }

  @include md() {
    margin-top: 3rem;
  }
}

.call-to-action {
  font-size: 2rem;
  line-height: 1.2;

  @include lg() {
    font-size: 1.8rem;
  }

  @include md {
    font-size: 1.5rem;
  }
}

.long-card-description {
  font-family: Oswald-regular;
  font-size: 2.2rem;
  margin-top: 1rem;

  @include lg {
    font-size: 2rem;
  }

  &.paragraph {
    font-size: 2rem;

    @include lg {
      font-size: 1.7rem;
    }
  }
}
