@font-face {
  font-family: "Oswald-light";
  font-style: normal;
  font-weight: 300;
  src: local("Oswald Light"), local("Oswald-Light"),
    url("./fonts/Oswald-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Oswald-regular";
  font-style: normal;
  font-weight: 400;
  src: local("Oswald Regular"), local("Oswald-Regular"),
    url("./fonts/Oswald-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Oswald-bold";
  font-style: normal;
  font-weight: 600;
  src: local("Oswald Bold"), local("Oswald-Bold"),
    url("./fonts/Oswald-Bold.ttf") format("truetype");
}
