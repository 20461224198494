@import "./Shared.scss";

.stacked-card {
  height: 450px;

  @include md {
    height: 300px;
  }

  @include sm {
    height: 400px
  }
}
