@import "./Shared.scss";

.talent-aquisition {
  & .accelerate {
    display: grid;
    margin-top: 5rem;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    @include md() {
      grid-template-columns: 1fr;
      margin-top: 3rem;
    }

    & h1 {
      font-size: 3.5rem;
      line-height: 1.2;

      @include lg() {
        font-size: 3.2rem;
      }

      @include md() {
        font-size: 2.7rem;
      }
    }

    & .description {
      font-size: 1.5rem;
      font-family: Oswald-regular;
      align-self: center;

      @include lg() {
        font-size: 1.3rem;
      }

      @include md {
        margin-top: 1rem;
      }
    }
  }

  & .customized {
    & .services-title {
      font-size: 3rem;
      text-align: center;
      margin-top: 5rem;
      margin-bottom: 2rem;

      @include md() {
        font-size: 2.5rem;
        margin-top: 3rem;
        line-height: 1.1;
      }
    }
  }
}
