@import "./Shared.scss";
.contact-us {
  position: relative;
  margin-bottom: -7rem;
  
  .content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
    margin-top: 4rem;
    place-items: center;

    @include lg {
      grid-template-columns: 1fr;

    }

    & .image {
      justify-self: baseline;

      @include lg {
      justify-self: center;

      }
      & img {
        max-width: 600px;
        width: 100%;
        min-width: 300px;
      }
    }
  }
}
