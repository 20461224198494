@import "./Shared.scss";

.executive-search {
  & .part-1,
  .part-2,
  .part-3 {
    margin-top: 5rem;

    @include md() {
      margin-top: 1rem;
    }
  }

  & .part-1 {
    & .content {
      @include md() {
        margin-top: 5rem;
      }

      @include md() {
        margin-top: 2rem;
      }
    }

    & .apply-images {
      position: relative;
      & .absolute-image {
        left: 0;
        bottom: 0;
        transform: translate(100px, 100px);

        @include md() {
          transform: translate(100px, 50px);
        }

        @include sm {
          left: 50%;
          bottom: -35%;
          transform: translate(-50%, -50%);
          max-width: 190px;
        }
      }
    }
  }

  & .part-2 {
    margin-top: 10rem;
    & .scalability {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
    @include md() {
      margin-top: 4rem;
    }

    & .right {
      & > p {
        margin-top: 1rem;
      }
    }
  }

  & .apply-images,
  .team-images {
    position: relative;
    max-height: 500px;
    margin-top: 4rem;
    text-align: center;

    @include lg() {
      max-height: 400px;
    }

    @include md() {
      max-width: 400px;
      align-self: center;
    }

    & .main-image {
      max-width: 500px;

      @include lg() {
        max-width: 400px;
      }

      @include md {
        max-width: 350px;
      }

      @include sm {
        max-width: 300px;
      }
    }
    & .absolute-image {
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translate(50px, -50px);
      width: 300px;

      @include lg() {
        max-width: 200px;
      }

      @include md() {
        transform: translate(50px, 0px);
      }
    }
  }

  & .team-images {
    @include md() {
      margin-bottom: 40px;
    }
    & .absolute-image {
      left: -100px;
      top: 0;

      @include md() {
        left: -50px;
        top: 0;
        width: 150px;
      }
    }
  }
}
