@import "./Shared.scss";

.ready {
  display: flex;
  background-color: $primaryBtnBgColour;
  padding: 3rem;
  position: relative;
  border-radius: 40px;
  margin-top: 5rem;
  margin-bottom: -12rem;

  @include md() {
    flex-direction: column;
    margin-bottom: 0;
    margin-top: 3rem;
  }

  & .content {
    & h1 {
      font-size: 3.5rem;

      @include md() {
        font-size: 2.5rem;
        line-height: 1;
        margin-bottom: 1rem;
      }
    }

    & p {
      font-size: 1.5rem;
      font-family: Oswald-regular;
      max-width: 80%;
      font-size: 1.2rem;
    }

    & .btn {
      background: $bgWhite;
      color: $primaryTextColour;
      margin-top: -1rem;

      @include md() {
        width: 100%;
      }
    }
  }

  & img {
    position: absolute;
    max-width: 450px;
    right: 0;
    bottom: 0;

    @include xl() {
      max-width: 350px;
      width: 90%;
    }

    @include md() {
      position: relative;
      align-self: flex-end;
      transform: translate(50px, 50px);
    }
  }
}
