@import "./Shared.scss";

.flex {
  display: flex;
  margin-top: 5rem;
  gap: 30px;

  @include lg() {
    flex-direction: column;
    align-items: center;
  }

  & .left {
    width: 90%;
    & .title {
      font-size: 7rem;
      line-height: 1.1;
      white-space: nowrap;
      @include xl() {
        font-size: 4rem;
      }

      @include lg() {
        font-size: 4rem;
      }
    }

    & .description {
      font-size: 1.6rem;
      font-family: Oswald-regular;
      max-width: 60%;
      line-height: 1.2;

      @include xl() {
        font-size: 1.8rem;
      }

      @include lg() {
        font-size: 1.4rem;
      }
    }

    & .subtitle {
      margin-top: 2rem;
    }
    & ul {
      margin-top: 1rem;
      margin-left: 1.5rem;
    }
  }

  .right {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    form {
      width: 90%;
    }
  }
}

.reviews {
  margin-top: 5rem;
}
