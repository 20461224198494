@import "./Shared.scss";

.clients-reviews-container {
  text-align: center;

  & p.description {
    font-family: Oswald-regular;
    font-size: 1.5rem;
    line-height: 1;
    margin-top: 2rem;

    @include md() {
      font-size: 1.3rem;
    }

    & .test {
      display: none !important;
    }
  }

  & .reviews {
    margin-top: 4rem;
    user-select: none;

    & .review {
      background: $bgWhite;
      box-shadow: 0px 0px 30px 5px $dropShadow;
      border-radius: 2rem;
      padding: 3rem;
      text-align: center;
      height: 450px;

      @include md {
        height: 400px;
      }

      @include sm {
        height: 520px;
        padding: 2rem 1rem;
      }

      @include xsm {
        height: 600px;
      }

      & .review-logo {
        margin-bottom: 1rem;
        & img {
          width: 150px;

          @include md {
            width: 100px;
          }
        }
      }

      & .review-text {
        font-family: Oswald-light;
        line-height: 1.5;
        font-size: 1.3rem;
        text-align: center;
        width: 80%;
        margin: 0 auto 3rem auto;

        @include md {
          margin: 0 auto 1rem auto;
          width: 100%;
          line-height: 1.2;
        }

        // @include sm {
        //   font-size: 1rem;
        // }
      }

      & .review-name {
        color: $textRed;
        font-size: 1.5rem;
      }

      & .review-role {
        color: $textBlue;
        font-size: 1rem;
      }
    }
  }
}

.review-carousel {
  height: 600px !important;

  @include sm {
    height: 700px !important;
  }
}
