$primaryBgColour: #e0f6f9;
$primaryTextColour: #393d40;
$bgWhite: #ffffff;
$max-width: 1920px;
$dropShadow: rgba(35, 191, 214, 0.25);
$footerBg: #C4EFF5;
//Text colors
$textRed: #f27e91;
$textBlue: #03bfdc;

//buttons style
$primaryBtnTextColour: #e82243;
$primaryBtnBgColour: #f7dd9b;

//cards
$cardBgPink: #fadddd;
$cardBgDarkPink: #f2c8ce;
$cardBgBlue: #e0f6f9;
$cardBgOrange: #f5c9b5;
$cardBgYellow: #fae8b9;

$borderColour: #393D40;