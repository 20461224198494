@import "./Shared.scss";

.hr-consulting {
  & .streamlined {
    font-size: 2rem;
    text-align: center;
    margin-top: 5rem;
    line-height: 1;
    flex-wrap: wrap;

    @include md() {
      font-size: 2.5rem;
    }

    & .streamlined-title {
      margin-bottom: 2rem;
    }
  }

  & .hr-power {
    margin-top: 5rem;

    & h1 {
      white-space: nowrap;
      margin-top: 2rem;

      @include md() {
        white-space: initial;
      }
    }
  }
}
