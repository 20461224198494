@import "./Shared.scss";

footer {
  display: flex;
  flex-direction: column;
  & .buildings {
    display: flex;
    width: 100vw;
    & img {
      width: 100%;
    }
  }

  & .footer-content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: "navlinks subscription social";
    padding: 3rem;
    background: $footerBg;

    @include md {
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        "navlinks social"
        "subscription subscription";
    }

    .links-container {
      display: flex;
      align-items: center;
      justify-content: center;
      grid-area: navlinks;

      @include md {
        justify-self: baseline;
      }

      & .links {
        & .link {
          list-style: none;
          font-family: Oswald-regular;
          font-size: 1.5rem;
          margin-bottom: 2rem;
        }
      }
    }

    & .subscription {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      grid-area: subscription;

      @include md {
        margin-top: 3rem;
        justify-self: center;
      }

      & .title {
        color: $textRed;
        font-family: Oswald-light;
        font-size: 1.5rem;
        line-height: 1.1;
        white-space: nowrap;
        text-align: center;
        @include lg {
          font-size: 1.3rem;
        }

        @include md {
          font-size: 1rem;
          text-align: left;
        }
      }

      & form {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include md {
          align-items: flex-start;
        }

        & .email-input {
          font-size: 1.6rem;
          padding: 1rem;
          border-radius: 2.5rem;
          border: none;
          font-family: "Oswald-light";
          width: 100%;
          outline: none;

          &::placeholder {
            color: rgba(57, 61, 64, 0.5);
          }

          @include lg {
            max-width: 500px;
          }

          @include md {
            width: 300px;
          }
        }

        & button {
          margin-top: 3rem;
          font-size: 1.5rem;
        }
      }
      & .emailLocation {
        display: flex;
        justify-content: space-between;

        @include md {
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        @include sm {
          align-items: start;
        }

        & .email,
        .location {
          display: flex;
          justify-content: center;
          align-items: center;
          min-width: 300px;

          @include md {
            justify-content: start;
            margin-top: 1rem;
          }

          & .icon {
            margin-right: 1rem;
          }

          & .content {
            display: flex;
            flex-direction: column;
            gap: 0.2rem;

            & .data {
              font-family: Oswald-regular;
            }
          }
        }
      }
    }

    & .social-media-links {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      grid-area: social;

      @include md {
        justify-self: end;
      }

      & img {
        width: 50px;
      }
    }
  }
}
