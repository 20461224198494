@import "./styles/Shared.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: $primaryBgColour;
  font-family: "Oswald-bold";
  color: $primaryTextColour;

  & #preLoader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: fadeInOut 0.5s infinite alternate linear;
    & .preLoader-image {
      width: 150px !important;
    }
  }

  & .container {
    margin: 0 auto;
    padding: 0 20px;
    max-width: 1550px;
  }

  & a {
    color: $primaryTextColour;
    text-decoration: none;

    & :active {
      color: $primaryTextColour;
    }
  }

  .btn {
    color: $primaryBtnTextColour;
    background: $primaryBtnBgColour;
    padding: 1rem;
    border-radius: 40px;
    display: inline-block;
    width: 305px;
    text-align: center;
    font-size: 2rem;
    border: none;
    cursor: pointer;
    font-family: Oswald-bold;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
  
  .section-title {
    font-size: 4rem;
    line-height: 1;
    @include lg {
      font-size: 3.5rem;
    }

    @include md {
      font-size: 2.8rem;
    }

    @include sm {
      font-size: 2.5rem;
    }
  }

  .card {
    background: $bgWhite;
    padding: 2rem 3rem;
    box-shadow: 0px 0px 30px 5px rgba(35, 191, 214, 0.25);
    border-radius: 37px;

    @include md {
      padding: 1.5rem 2rem;
    }

    & .title {
      font-size: 2rem;
      margin-bottom: 1rem;
      line-height: 1.1;

      @include md() {
        font-size: 2rem;
      }
    }

    & .description {
      font-size: 1.3rem;
      font-family: Oswald-regular;
      @include md() {
        font-size: 1.2rem;
      }
    }
  }

  & .card-pink {
    background: $cardBgPink;
  }

  & .card-dark-pink {
    background: $cardBgDarkPink;
  }

  & .card-blue {
    background: $cardBgBlue;
  }

  & .card-orange {
    background: $cardBgOrange;
  }

  & .card-yellow {
    background: $cardBgYellow;
  }

  .hidden-md {
    @include md() {
      display: none;
    }
  }

    .show-md {
      display: none;
    @include md-min() {
      display: block;
    }
  }

  ._flex {
    display: flex;

    @include md() {
      flex-direction: column;
    }
  }

  .col-reverse-md {
    @include md() {
      flex-direction: column-reverse;
    }
  }

  .gap-30 {
    gap: 30px;

    @include md() {
      gap: 10px;
    }
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;

    @include md() {
    grid-template-columns: 1fr;

    }
  }
}

@keyframes fadeInOut {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}