@import "./Shared.scss";

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: $bgWhite;
  padding: 2rem;
  width: 100%;
  border-radius: 40px;
  box-shadow: 0px 0px 30px 5px $dropShadow;

  & label {
    display: flex;
    flex-direction: column;
    font-family: Oswald-bold;
    font-size: 1rem;

    & .hint {
      font-family: Oswald-regular;
      font-size: 1rem;
      margin-left: 0.5rem;
    }
  }

  & .form-input {
    padding: 0.8rem;
    font-size: 1rem;
    font-family: Oswald-regular;
    border-radius: 0.5rem;
    border: 0.5px solid #393d40;

    &:focus {
      outline: none;
    }
  }

  & textarea {
    resize: none;
  }

  & input[type="submit"] {
    align-self: center;
    max-width: 250px;
    font-size: 1.3rem;
    margin: 1rem 0 1rem 0;

    @include lg {
      align-self: center;
    }
  }
}
