// Small tablets and large smartphones (landscape view)
$screen-xsm-max: 430px;

// Small tablets and large smartphones (landscape view)
$screen-sm-max: 576px;

// Small tablets (portrait view)
$screen-md-max: 768px;

// Tablets and small desktops
$screen-lg-max: 992px;

// Large tablets and desktops
$screen-xl-max: 1200px;

//really small
@mixin xsm {
  @media (max-width: #{$screen-xsm-max}) {
    @content;
  }
}

// Small devices
@mixin sm {
  @media (max-width: #{$screen-sm-max}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (max-width: #{$screen-md-max}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (max-width: #{$screen-lg-max}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (max-width: #{$screen-xl-max}) {
    @content;
  }
}


// Extra large devices
@mixin xl-min {
  @media (min-width: #{$screen-xl-max}) {
    @content;
  }
}

@mixin md-min {
    @media (min-width: #{$screen-md-max}) {
    @content;
  }
}