@import "./Shared.scss";

.main {
  margin-top: 5rem;
  display: grid;
  grid-template-areas:
    "about-title about-description"
    "history history-description";
  grid-template-columns: 0.7fr 1.5fr;
  gap: 40px;

  @include md() {
    grid-template-areas:
      "about-title"
      "about-description"
      "history"
      "history-description";
    grid-template-columns: 1fr;

    place-items: center;
  }

  & .section-title {
    @include md {
      font-size: 2rem;
    }
  }

  & > p {
      @include md {
        margin-top: 0;
        font-size: 1.2rem !important;
      };
  }

  .title {
    font-size: 3.4rem;
    line-height: 1;
    font-family: Oswald-bold;
    grid-area: about-title;

    @include xl-min() {
      font-size: 4rem;
    }

    @include lg() {
      font-size: 3.2rem;
    }

    @include sm() {
      font-size: 2.5rem;
    }
  }

  & .description {
    font-size: 1.8rem;
    font-family: Oswald-regular;
    grid-area: about-description;

    @include xl-min() {
      font-size: 2rem;
    }

    @include lg() {
      font-size: 1.5rem;
    }
  }

  & .history {
    background: white;
    border-radius: 1.7rem;
    padding: 2rem;
    box-shadow: 0px 0px 30px 5px rgba(35, 191, 214, 0.25);
    grid-area: history;
    max-height: 550px;

    & .title {
      font-size: 2.5rem;
      line-height: 1;
      margin-bottom: 1rem;

      @include md {
        font-size: 2rem;
      }
    }

    & .description {
      font-family: Oswald-regular;
      font-size: 1.2rem;
    }
  }

  & .at-eboo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
    background-color: $primaryBtnBgColour;
    box-shadow: 0px 0px 30px 5px rgba(35, 191, 214, 0.25);
    border-radius: 1.7rem;
    padding: 2rem 2rem 2rem 0;
    grid-area: history-description;

    @include lg() {
      flex-direction: column;
      justify-content: center;
      overflow: hidden;
      gap: 10px;
    }

    & .image {
      @include lg() {
        align-self: baseline;
        transform: translateY(-50px);
      }

      & .img-normal {
        width: 260px;
        @include lg() {
          display: none;
        }
      }

      & .img-small-screen {
        display: none;

        @include lg() {
          display: block;
        }
      }
    }
    & .description-wrapper {
      width: 100%;
      margin-left: 2rem;

      & .description {
        font-size: 1.8rem;
        font-family: Oswald-regular;

        @include xl-min() {
          font-size: 2rem;
        }

        @include xl() {
          font-size: 1.3rem;
        }

        @include lg() {
          font-size: 1.5rem;
        }

        @include md {
          font-size: 1.2rem;
        }
      }
    }
  }
}

.meet-the-team {
  background: $bgWhite;
  margin-top: 10rem;
  box-shadow: 0px 0px 30px 5px rgba(35, 191, 214, 0.25);
  padding: 3rem 0;
  display: flex;
  overflow-x: scroll;

  @include md {
    margin-top: 3rem;
  }

  & .title {
    font-size: 5rem;
    font-family: Oswald-bold;
    margin-bottom: 3rem;
  }

  & .members {
    display: flex;
    gap: 30px;

    & .member {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 350px;

      & .image {
        position: relative;

        & .frame {
          width: 300px;
          height: 300px;
        }

        & .member-image {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 250px;
          height: 250px;
        }

        & .frame-2,
        .frame-4 {
          transform: rotate(80deg);
        }

        & .frame-3 {
          transform: rotate(30deg);
        }
        & .frame-5 {
          transform: rotate(190deg);
        }
      }

      & .name {
        margin-top: 1.3rem;
        font-size: 1rem;
      }

      & .role {
        font-size: 1.8rem;
        font-family: Oswald-light;
        white-space: nowrap;
      }

      & .description {
        font-size: 0.9rem;
        max-width: 500px;
        text-align: center;
        font-family: Oswald-regular;
        width: 80%;

      }
    }
  }
}

.small-paragraph {
  margin-top: 10rem;
  display: grid;
  grid-template-columns: 1fr 1.5fr;

  @include md() {
    grid-template-columns: 1fr;
    padding: 1rem;
    margin-top: 3rem;
  }

  & img {
    @include xl() {
      width: 350px;
    }

    @include lg() {
      width: 300px;
    }

    @include md() {
      display: none;
    }
  }

  & .content {
    margin: 0 auto;
    max-width: 1000px;

    @include md() {
      width: 100%;
    }

    & .title {
      font-size: 3rem;
      line-height: 1;

      @include xl() {
        font-size: 2.6rem;
      }

      @include md() {
        font-size: 2.3rem;
      }
    }

    & .description {
      margin-top: 2rem;
      font-size: 2.5rem;
      line-height: 1.1;

      @include xl() {
        font-size: 1.8rem;
      }

      @include md() {
        font-size: 1.6rem;
      }
    }
  }
}
