@import "./Shared.scss";
nav {
  width: 100%;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  z-index: 99;
  position: sticky;

  @include md() {
    margin-top: 0;
  }

  & .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 92%;
    max-width: $max-width;

    @include md() {
      flex-direction: column;
      background: $bgWhite;
      width: 100%;
    }

    & .logo-hamburger-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 1rem;
      cursor: pointer;
    }
  }

  & .logo {
    z-index: 2;

    & img {
      max-width: 200px;
    }
  }

  & .hamburger {
    cursor: pointer;
    display: none;
    position: relative;
    z-index: 5;

    @include md() {
      display: block;
    }
  }

  & .topnav-links {
    & .links-wrapper {
      display: flex;
      align-items: center;
      gap: 3rem;
      margin-right: 5rem;
      white-space: nowrap;

      @include md() {
        display: none;
        gap: 0;
        margin-right: 0;
        position: relative;
      }

      @include lg() {
        gap: 0.6rem;
      }

      &.active {
        @include md() {
          display: flex;
          align-items: center;
          flex-direction: column;
          top: 0;
          left: 0;
          right: 0;
          width: 100vw;
          background: $bgWhite;
        }
      }

      & .link {
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        font-size: 1.3rem;

        & .icon {
          transform: scale(0.6);
        }
      }
    }

    & .services-wrapper {
      .services-all {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      & .services {
        position: relative;

        @include md() {
          position: static;
        }
      }

      & .sub-services {
        display: none;
        position: absolute;
        background: $bgWhite;
        font-family: Oswald-regular;
        padding: 0.4rem;
        border-radius: 1rem;
        box-shadow: 0px 0px 30px 5px rgba(35, 191, 214, 0.25);
        z-index: 10;

        &.active {
          display: block;
        }

        @include md() {
          top: 0;
          right: 0;
          width: 100%;
          box-shadow: none;
          z-index: 1;
        }

        & .link {
          justify-content: start;
          align-items: center;
          gap: 10px;
          margin-bottom: 0.6rem;

          &:hover {
            background: #f7f7f7;
            border-radius: 0.7rem;
          }
          & .icon {
            transform: scale(0.9);
            margin-top: 3px;
          }

          @include md() {
            margin: 0 0 20px 40px;
          }
        }
      }
    }
  }
}
