@import "./Shared.scss";
main {
  overflow-x: hidden;
  & .images {
    position: absolute;
    display: grid;
    grid-template-areas:
      "rocket eboo"
      "plane eboo";
    grid-template-columns: 1fr 1fr;
    gap: 400px 400px;
    margin-left: 50px;
    z-index: -1;

    @include xl() {
      gap: 300px 200px;
      margin-top: -250px;
      margin-left: 0px;
    }

    @include md() {
      position: relative;
      margin-top: 100px;
      width: 100%;
      gap: 20%;
    }

    &::after {
      content: "";
      display: inline-block;
      width: 120vw;
      height: 140vh;
      z-index: -1;
      top: -3%;
      left: -10%;
      background: url("../../public/assets/icons/blue-banner.svg") no-repeat
        center center/cover;
      position: absolute;

      @include md() {
        top: 15%;
        left: 0;
        width: 100vw;
        height: 60vh;
      }
    }

    & .chat-elements {
      grid-area: eboo;
      display: flex;
      align-items: flex-start;
      margin-top: 100px;

      & img.elements {
        width: 700px;
        z-index: 1;
        @include md() {
          transform: translateX(-50px);
          width: 400px;
        }

        @include sm {
          transform: translateX(-100px);

        }
      }

      & img.chat {
        margin-top: 100px;
        transform: translate(-100px, -80px);
        @include md() {
          width: 100px;
          transform: translate(-30px, -50px);
        }

        @include sm {
          transform: translate(-30px, -100px);
          width: 80px;;

        }
      }
    }

    & img.rocket {
      width: 225px;
      grid-area: rocket;
      @include md() {
        width: 100px;
      }

      @include sm {
        transform: translateY(50px);
      }
    }

    & img.paper-plane {
      width: 180px;
      grid-area: plane;
      align-self: flex-end;

      @include md() {
        width: 100px;
        margin-left: 20px;
        transform: translateY(-50px);
      }
    }
  }

  & img.buildings-md {
    display: none;
    @include md() {
      display: block;
      position: absolute;
      width: 200vw;
      transform: translateX(-300px);
      z-index: -2;
      top: -60px;
    }
  }

  & img.buildings-lg {
    position: absolute;
    bottom: 0px;
    width: 100%;
    z-index: -2;

    @include md() {
      display: none;
    }
  }

  & div.text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    min-height: 100vh;
    overflow: hidden;
    z-index: 10;

    & .header-paragraph {
      margin-left: 5%;
      margin-top: 5%;

      @include xl() {
        margin-top: 0;
      }

      @include md {
        margin-top: 4rem;
      }

      & h1 {
        color: $textRed;
        font-size: 5rem;
        line-height: 1;

        @include xl() {
          font-size: 4rem;
        }

        @include md() {
          font-size: 3.3rem;
        }
      }

      & p {
        font-family: Oswald-light;
        font-size: 2rem;
        line-height: 1;
        margin-bottom: 2rem;

        @include xl() {
          font-size: 1.8rem;
        }

        @include md() {
          font-size: 1.5rem;
          margin-top: 2rem;
        }
      }
    }

    & .contact {
      align-self: center;
      transform: translateY(80px);
      z-index: 99;

      @include md() {
        width: 80%;
        align-self: center;
        transform: translate(0, 0);
        font-size: 1.5rem;
        margin-top: 2rem;
      }
    }
  }

  & .blur-rectangle {
    position: absolute;
    bottom: -100px;
    width: 100%;
    height: 259px;
    background: linear-gradient(
      0deg,
      #e0f6f9 49.81%,
      rgba(224, 246, 249, 0) 100%
    );
  }
}

section {
  &.services {
    margin-top: 7rem;
    & .section-title {
      margin-bottom: 2rem;
    }

    .react-stacked-center-carousel {
      height: 500px !important;
      margin-left: 100px;

      @include md {
        margin-left: 0px;
      }
      @include md {
        max-height: 400px;
        height: 400px !important;
      }
    }

    .panel {
      max-width: 500px;
      & .title {
        font-size: 3.5rem;
        line-height: 1;

        @include md {
          font-size: 2.3rem;
        }
      }

      & .description {
        @include md {
          font-size: 1rem;
        }
      }
    }

    .card {
      user-select: none;
      padding: 5rem;

      @include md {
        padding: 3rem;
      }
    }
  }

  &.industries {
    margin-top: 5rem;

    @include md {
      margin-top: 2rem;
    }

    & .cards {
      margin-top: 2rem;
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: space-between;
      gap: 4rem;

      @include md() {
        grid-template-columns: 1fr;
        place-items: center;
      }

      & .card {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        border-radius: 37px;
        box-shadow: 0px 0px 30px 5px $dropShadow;
        padding: 50px 54px 50px 48px;

        & span.icon {
          @include md {
            transform: scale(0.7);
          }
        }

        & span.talent {
          margin: 30px 0 20px 0;
          font-size: 1.7rem;

          @include xl {
            font-size: 1.5rem;
          }

          @include lg {
            font-size: 1.3rem;
          }

          @include md {
            margin-top: 1rem;
          }
        }

        & .title {
          font-size: 3rem;
          line-height: 1;

          @include xl {
            font-size: 3rem;
          }

          @include lg {
            font-size: 2.5rem;
          }

          @include md {
            font-size: 2rem;
          }
        }

        & .description {
          font-family: Oswald-regular;
          margin-top: 1rem;
          font-size: 1.3rem;

          @include xl {
            font-size: 1.1rem;
          }

          @include lg {
            font-size: 1rem;
          }
        }
      }
    }
  }

  &.about {
    margin-top: 7rem;
    display: grid;
    grid-template-columns: 0.5fr 0.4fr 0.2fr;

    @include lg() {
      grid-template-columns: 1fr 1fr;
    }

    @include md() {
      grid-template-columns: 1fr;
    }

    & .about-logo {
      max-width: 350px;
      min-width: 250px;

      @include md() {
        width: 100px;
      }
    }

    & div.about {
      @include lg {
        text-align: left;
      }

      @include lg {
        margin-top: 2rem;
        width: 80%;
      }

      @include md() {
        width: 100%;
        padding: 0 20px;
      }

      & .description {
        font-size: 1.5rem;
        font-family: Oswald-regular;

        @include lg() {
          font-size: 1.3rem;
        }
      }
      & .learn-more-container {
        @include lg {
          width: 100%;
          text-align: center;
        }
        & .learn-more {
          margin-top: 2rem;

          @include lg {
            width: 90%;
            font-size: 1.7rem;
          }
        }
      }
    }
  }

  &.contact-us {
    margin-top: 3rem;

    @include md {
      margin-top: 0;
    }
  }

  &.clients-reviews {
    margin-top: 5rem;
  }
}
